<template>
  <div>
    <h4>{{ i18n['profile-prospect-engagement'].tcCandidateEngagement }}</h4>
    <div class="specs">
      <p>
        <strong>{{ i18n['profile-prospect-engagement'].tcInvitedToAnnualMembershipDinner }}:</strong>
        {{ profile.invited_annual_dinner }}
      </p>
      <p>
        <strong>{{ i18n['profile-prospect-engagement'].tcSolicitedForIndividualEnlistment }}:</strong>
        <br />
        {{ profile.solicited_for_enlistment }}
      </p>
      <p>
        <strong>{{ i18n['profile-prospect-engagement'].tcAttendedOtherEvents }}:</strong>
        <br />
        {{ profile.attended_other_events }}
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'profile-prospect-engagement',
  props: {
    profile: {
      invited_annual_dinner: {
        type: String,
        default: '',
      },
      solicited_for_enlistment: {
        type: String,
        default: '',
      },
      attended_other_events: {
        type: String,
        default: '',
      },
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'profile-prospect-engagement': {
            tcCandidateEngagement: 'Candidate Engagement',
            tcInvitedToAnnualMembershipDinner: 'Invited to Annual Membership Dinner',
            tcSolicitedForIndividualEnlistment: 'Solicited for Individual Enlistment',
            tcAttendedOtherEvents: 'Attended Other Events',
          },
        }
      },
    },
    data() {
      return {}
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
