<template>
  <div class="church-details template-2">
    <header class="page-header container mx-lg d-flex">
      <h1>{{ translations.tcCandidateProfile }}</h1>
    </header>

    <!-- / Page Header -->
    <div class="page-body container mx-lg">
      <div class="details">
        <div class="d-flex row primary">
          <div class="col">
            <profile-contact-info :profile="individualProfile" @reloadProfile="pageLoad()"></profile-contact-info>
          </div>
          <div class="col">
            <profile-prospect-basic-info
              :profile="individualProfile"
              :i18n="translations.components"
            ></profile-prospect-basic-info>
          </div>
          <div class="col">
            <profile-spouse-info
              :isAbleToAddSpouse="iCanSee(secured_controls.friend_add_spouse_button)"
              v-if="!individualProfile.widow_flag"
              :profile="individualProfile"
              @changeIndividual="pageLoad()"
            ></profile-spouse-info>
          </div>
        </div>
        <div class="row d-flex events" v-if="showEvents">
          <div class="col col-4">
            <app-event :obj="dateAddedObj" :i18n="translations.components"></app-event>
          </div>
          <div class="col col-4">
            <app-event :obj="lastUpdatedObj" :i18n="translations.components"></app-event>
          </div>
          <div class="col col-4">
            <app-event :obj="lastContactedObj" :i18n="translations.components"></app-event>
          </div>
        </div>
        <div class="d-flex">
          <a
            download
            class="btn btn-primary mr-4"
            @click.prevent="downloadReport(prospectProfileReportAsPDF, 'CandidateDetailReport', 'pdf')"
            >{{ translations.tcRunDetailedCandidateReport }}</a
          >
          <a
            href="#"
            class="btn btn-primary mr-4"
            v-if="iCanSee(secured_note_add_controls.add_non_member_profile_note_button)"
            @click.prevent="noteStoreAndRedirect({})"
            >{{ translations.tcAddContactNote }}</a
          >
        </div>
      </div>
      <div class="actions">
        <div class="card-deck row">
          <div class="card card-secondary col">
            <div :style="{ height: '4px' }"></div>
            <svg-contact-information></svg-contact-information>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcCandidateCommunicationsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcCandidateCommunicationsDescription }}
              </p>
              <b-button to="/programs/mb/communications/prospect" variant="primary">{{
                translations.tcViewMore
              }}</b-button>
            </div>
          </div>
          <div class="card card-secondary col">
            <div :style="{ height: '5px' }"></div>
            <svgChurchOfficer></svgChurchOfficer>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcCandidateDetailsTitle }}</h5>
              <p class="card-text text-left">{{ translations.tcCandidateDetailsDescription }}.</p>
              <b-button :to="{ name: 'prospect-details' }" variant="primary">{{ translations.tcViewMore }}</b-button>
            </div>
          </div>
          <div class="card card-secondary col">
            <div :style="{ height: '5px' }"></div>
            <svgVisits></svgVisits>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcContactNotesTitle }}</h5>
              <p class="card-text text-left">{{ translations.tcContactNotesDescription }}</p>
              <b-button :to="{ name: 'prospect-contact-notes' }" variant="primary">{{
                translations.tcViewMore
              }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Page Body -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import ChurchOfficer from '@/assets/svgs/church-officers.vue'
import ContactInformation from '@/assets/svgs/contact-information.vue'
import date_data from '@/json/date.json'
import Event from '@/components/Event.vue'
import ProfileContactInfo from '@/components/ProfileContactInfo'
import ProfileSpouseInfo from '@/components/ProfileSpouseInfo'
import ProfileProspectBasicInfo from '@/components/ProfileProspectBasicInfo'
import ProfileProspectEngagement from '@/components/ProfileProspectEngagement'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'
import { translationMixin } from '@/mixins/translationMixin'
import Visits from '@/assets/svgs/visits.vue'

export default {
  name: 'prospect-profile',
  mixins: [translationMixin, reportDownloadMixin],
  data() {
    return {
      translations: {},
      secured_controls: {
        friend_add_spouse_button: 'e9cc3499-5e03-45ed-8935-bf903056c13c',
      },
      secured_note_add_controls: {
        add_non_member_profile_note_button: '862acc5a-0576-4d47-b8f5-5683daac032c',
      },
      showEvents: false,
    }
  },
  components: {
    appEvent: Event,
    profileContactInfo: ProfileContactInfo,
    profileSpouseInfo: ProfileSpouseInfo,
    profileProspectBasicInfo: ProfileProspectBasicInfo,
    profileProspectEngagement: ProfileProspectEngagement,
    svgContactInformation: ContactInformation,
    svgChurchOfficer: ChurchOfficer,
    svgVisits: Visits,
  },
  methods: {
    ...mapActions({
      loadIndividualProfile: 'membership/loadIndividualProfile',
      loadProspectProfileReportUrls: 'membership/loadProspectProfileReportUrls',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedNote: 'user/setSelectedNote',
    }),
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.loadIndividualProfile(),
        await this.loadProspectProfileReportUrls(),
        await this.checkMemberType(),
      ]).then(() => {
        this.showEvents = true
        this.setLoadingStatus(false)
      })
    },
    async noteStoreAndRedirect(data) {
      this.setSelectedNote(data)
      this.$router.push({ name: 'prospect-add-note' })
    },
    async checkMemberType() {
      let hasMemberTypeKey = this.individualProfile.member_type_key ? true : false
      let hasProspectTypeKey = this.individualProfile.prospect_met_key ? true : false
      if (hasMemberTypeKey) {
        this.$router.push('/programs/mb/member-profile')
      }
      if (!hasProspectTypeKey && !hasMemberTypeKey) {
        this.$router.push('/programs/mb/non-member-profile-mb')
      }
    },
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      individualProfile: 'membership/individualProfile',
      prefCulture: 'user/userPreferredCulture',
      prospectProfileReportAsPDF: 'membership/prospectProfileReportAsPDF',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    months() {
      return date_data.months.map((dm) => dm.abbrlow)
    },
    dateAddedObj() {
      var addDate = !!this.individualProfile.join_date ? new Date(this.individualProfile.join_date) : null
      var obj = {
        title: this.translations.tcDateAdded,
        month: !!addDate ? this.months[addDate.getMonth()] : '',
        day: !!addDate ? addDate.getDate() : '',
        year: !!addDate ? addDate.getFullYear() : '',
        fullDate: !!this.individualProfile.join_date ? this.individualProfile.join_date : '',
      }
      return obj
    },
    lastUpdatedObj() {
      var allDates = [
        this.individualProfile.ind_last_update_date,
        this.individualProfile.adr_last_update_date,
        this.individualProfile.eml_last_update_date,
        this.individualProfile.phn_last_update_date,
        this.individualProfile.fax_last_update_date,
      ]

      var lastDate = allDates
        .filter((d) => !!d)
        .map((d) => {
          return new Date(!!d ? d : null)
        })
        .sort((a, b) => b - a)

      var addDate = lastDate.length > 0 ? lastDate[0] : null

      var obj = {
        title: this.translations.tcLastUpdated,
        month: !!addDate ? this.months[addDate.getMonth()] : '',
        day: !!addDate ? addDate.getDate() : '',
        year: !!addDate ? addDate.getFullYear() : '',
        fullDate: !!addDate ? addDate : '',
      }
      return obj
    },
    lastContactedObj() {
      var addDate = !!this.individualProfile.last_contact_date
        ? new Date(this.individualProfile.last_contact_date)
        : null

      var obj = {
        title: this.translations.tcLastContact,
        month: !!addDate ? this.months[addDate.getMonth()] : '',
        day: !!addDate ? addDate.getDate() : '',
        year: !!addDate ? addDate.getFullYear() : '',
        fullDate: !!addDate ? addDate : '',
      }
      return obj
    },
  },
  async created() {
    this.showEvents = false
    //need a selected individual or redirect back to membership landing
    if (!this.userSelectedIndividualKey) {
      this.$router.push({ name: 'membership' })
    }
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('event', 'profile-basic-info'),
      this.getComponentTranslations('common.occupation-type'),
      this.pageLoad(),
    ]).then((results) => {
      this.stripReadableText(results[2])
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common },
      }
      this.$set(this.translations, 'components', translatedText)
      this.setTranslatedOccupationName()
    })
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.col-3.date {
  min-height: 118px;
}
.church-details {
  form {
    .quick-jump {
      min-width: 201px;
    }
  }
}
.church_image {
  min-width: 251px;
  min-height: 185px;
}
.details {
  margin-bottom: 46px;
  padding: 32px 40px 40px;
  background-color: #fff;
  box-shadow: 0 9px 24px 6px rgba(197, 197, 197, 0.53);
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
      > * {
        margin-bottom: 2rem;
        margin-right: 0 !important;
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.primary {
  margin-bottom: 35px;
  > .col {
    @include breakpoint(sm) {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  h5 {
    margin: 0;
    color: #1e68fb;
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 1.03px;
    line-height: 26px;
  }
  strong {
    font-weight: 800;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 17px;
    font-weight: 600;
    letter-spacing: 1.03px;
    line-height: 26px;
  }
  img {
    margin-top: 17px;
  }
  a {
    color: rgba(30, 104, 251, 1);
    text-decoration: underline;
    &:hover {
      color: rgba(30, 104, 251, 0.8);
    }
  }
  .btn {
    color: #fff;
    text-decoration: none;
  }
  img {
    margin: 0;
  }
  .specs {
    p {
      margin-bottom: 10px;
      line-height: 22px;
      &:last-of-type {
        margin-bottom: 17px;
      }
    }
  }
  .contact {
    strong {
      font-weight: 700;
      text-transform: capitalize;
    }
  }
}

.events {
  margin-bottom: 50px;
  .col-4 {
    @include breakpoint(sm) {
      max-width: 100%;
      width: 100%;
      flex: 0 0 auto;
      &:last-of-type .event {
        margin-bottom: 0;
      }
    }
  }
  .event {
    margin-bottom: 0;
    border: 1px solid #e9e9e9;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    h3 {
      color: $teal-800;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}

.actions {
  @include breakpoint(sm) {
    padding: 0 1rem;
  }
}

.card-deck {
  .card {
    svg {
      display: block;
      margin: 0 auto 13px;
    }
  }
}
.card-deck.row {
  margin-bottom: 30px;

  @include breakpoint(mobile) {
    display: block;
  }
}
</style>
